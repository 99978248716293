import Pptxgen from 'pptxgenjs';
import { DateObject } from 'react-multi-date-picker';
import { User } from '@auth0/auth0-spa-js';

import {
  ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE,
  MAP_GRAPH_PREFIX_BY_HEADER_TEXT_FOR_BASELINE,
  SLIDE_STYLE,
  SLIDE_TYPE,
} from '../../constant/LocationMonthlyReviewPowerpoint';
import {
  insertGraphForBaseline,
  insertImageSlidesForBaseline,
  insertImageSlidesForIssueForBaseline,
  insertTableForBaseline,
  initialiseSlide,
  insertBaselineNextStepsBodySlides,
  insertSummaryValues,
} from '../../functions/PowerpointFunctions';
import { GroupedServiceBaselineReportData, Service } from '../../interfaces';

const NUMBER_OF_IMAGES_DISPLAYED_FOR_INTERNAL_GROUPING = 4;

/**
 * Get the number of days within a date range
 * Note: The specified dates are included
 * @param dateRange - Date range to find the number of days it consists
 * @returns number of days within a date range
 */
const getNumberOfDayWithinDateRange = (dateRange: Array<DateObject>) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const startDate = dateRange[0] as any;
  const endDate = dateRange[1] as any;
  return Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;
};

/**
 * This function holds the logic required to extract the pptData provided by the backend and plots them within
 * a predetermined slide order template structure.
 * The Backend passes back the data in a structure that can be read by Frontend functions as easy as possible without much sorting
 * and calculations.
 * @param fetchBaselineGraphRes - Response from backend containing information required to be plot into the powerpoint slides
 * @param formState - Report form header values used for powerpoint slide headers
 * @param user - User provided by Auth0 SDK to place the user's email address in the closing slide
 */
const downloadBaselineReviewPowerpoint = async (
  fetchBaselineGraphRes: any,
  formState: {
    companyField: string;
    restaurantField: string;
    locationField: string;
    arrSelectedService: Array<Service>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
  },
  user: User | undefined
) => {
  const { baselineReportData, restaurantService, fileName } = fetchBaselineGraphRes;
  const { name: restaurantName, arrLocationService } = restaurantService;
  const { name: locationName } = arrLocationService[0];
  const { dateRange } = formState;
  const numberOfDayWithinDateRange = getNumberOfDayWithinDateRange(dateRange);
  const locationData = baselineReportData;

  // Powerpoint creation
  const pres = new Pptxgen();
  ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE.forEach((slideTemplate) => {
    const { slideStyle } = slideTemplate;
    // Opening Slide
    if (slideStyle === SLIDE_STYLE.opening) {
      const openingText = `${restaurantName}\nBaseline Review (${dateRange[1].format(
        'MMMM-YYYY'
      )})`;
      initialiseSlide(pres, slideStyle, openingText);
      return;
    }
    // Section Slide
    else if (slideStyle === SLIDE_STYLE.section) {
      const { headerText, hasTextToAdd } = slideTemplate;
      if (hasTextToAdd) {
        const sectionText = `${headerText}\n${locationName}`;
        initialiseSlide(pres, slideStyle, sectionText);
      } else {
        initialiseSlide(pres, slideStyle, headerText);
        insertBaselineNextStepsBodySlides(pres, restaurantName);
      }
      return;
    } else if (slideStyle === SLIDE_STYLE.subSection) {
      const { headerText } = slideTemplate;
      initialiseSlide(pres, slideStyle, headerText);
    }
    // Closing Slide
    else if (slideStyle === SLIDE_STYLE.closing) {
      initialiseSlide(pres, slideStyle, user?.email);
    }
    // Plain Slide
    else if (slideStyle === SLIDE_STYLE.plain) {
      const { headerText, slideType, dynamic, grouped } = slideTemplate;
      if (headerText === 'SUMMARY') {
        let slide = initialiseSlide(pres, slideStyle, headerText);
        insertSummaryValues(
          slide,
          locationData.overview.graph.wasteValue,
          numberOfDayWithinDateRange
        );
      } else if (!dynamic) {
        let slide = initialiseSlide(pres, slideStyle, headerText);
        if (slideType === SLIDE_TYPE.graph) {
          insertGraphForBaseline(slide, locationData.overview.graph.image);
        } else if (slideType === SLIDE_TYPE.table) {
          const {
            group: arrNameAndWeightPerCoverForGroupedServices,
            individual: arrNameAndWeightPerCoverForServices,
          } = locationData.overview.table.byWeightPerCover;
          insertTableForBaseline(pres, slide, headerText, arrNameAndWeightPerCoverForServices);
          // For grouped service(s)
          const isAllServiceFromSameGroup =
            arrNameAndWeightPerCoverForGroupedServices.length ===
            arrNameAndWeightPerCoverForServices.length;
          if (!isAllServiceFromSameGroup) {
            const headerTextForGroup = `${headerText} (GROUPED)`;
            slide = initialiseSlide(pres, slideStyle, headerTextForGroup);
            insertTableForBaseline(
              pres,
              slide,
              headerTextForGroup,
              arrNameAndWeightPerCoverForGroupedServices
            );
          }
        }
      } else if (grouped) {
        const { arrSlideGraphProp } = slideTemplate;
        locationData.breakdown.group.forEach(
          (groupedServiceBaselineReportData: GroupedServiceBaselineReportData) => {
            const {
              name,
              graph,
              weight,
              weightPerCover,
              arrServiceNameAndTopWasteImageAndTop6WasteItem,
            } = groupedServiceBaselineReportData;
            arrSlideGraphProp?.forEach(
              (
                slideProp: {
                  headerText: string;
                  slideType: string;
                  weightCostType?: string;
                  isFullWidth?: boolean;
                  isDisplayWeight?: boolean;
                },
                index: number
              ) => {
                const { headerText, isFullWidth, isDisplayWeight } = slideProp;
                const graphName = MAP_GRAPH_PREFIX_BY_HEADER_TEXT_FOR_BASELINE.get(headerText!)!;
                // If service is production, it will only have 1 graph "LEFTOVER IN TERMS OF WEIGHT AND THROW (BY DAYS)"
                if (!graph[graphName]) {
                  return;
                }
                let slide = initialiseSlide(
                  pres,
                  slideStyle,
                  `${name.toUpperCase()} - ${headerText}`
                );
                insertGraphForBaseline(
                  slide,
                  graph[graphName],
                  isDisplayWeight ? weight : weightPerCover,
                  {
                    isWeight: index !== arrSlideGraphProp.length - 1,
                    isFullWidth,
                  }
                );
              }
            );
            arrServiceNameAndTopWasteImageAndTop6WasteItem.forEach(
              (serviceNameAndTopWasteImageAndTop6WasteItem: any) => {
                const {
                  serviceName,
                  arrTop3InternalGroupingDetailByWeight,
                  arrTopXWasteImageSignedUrlByWeight,
                } = serviceNameAndTopWasteImageAndTop6WasteItem;
                const headerTextForServicePortion = `${name.toUpperCase()}${
                  serviceName ? ` - ${serviceName}` : ''
                }`;
                insertImageSlidesForBaseline(
                  pres,
                  headerTextForServicePortion,
                  arrTop3InternalGroupingDetailByWeight,
                  arrTopXWasteImageSignedUrlByWeight,
                  NUMBER_OF_IMAGES_DISPLAYED_FOR_INTERNAL_GROUPING
                );
              }
            );
          }
        );
      } else {
        const { arrSlideProp } = slideTemplate;
        arrSlideProp?.forEach((slideProp) => {
          const { headerText } = slideProp;
          if (headerText === 'SINGULAR THROWS') {
            insertImageSlidesForIssueForBaseline(
              pres,
              headerText,
              locationData.breakdown.individual.notInMenu
            );
          } else if (headerText === 'MIXED THROWS') {
            insertImageSlidesForIssueForBaseline(
              pres,
              headerText,
              locationData.breakdown.individual.mixed
            );
          } else {
            insertImageSlidesForIssueForBaseline(
              pres,
              headerText,
              locationData.breakdown.individual.usageIssue
            );
          }
        });
      }
    }
  });
  await pres.writeFile({ fileName });
};

export default downloadBaselineReviewPowerpoint;
