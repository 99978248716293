import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import {
  MdAirplanemodeActive,
  MdKeyboardArrowDown,
  MdMinimize,
  MdOpenInFull,
} from 'react-icons/md';
import { IconButton } from '@material-tailwind/react';

import {
  Company,
  CompanyService,
  Location,
  LocationService,
  NewRestaurantService,
  NewLocationService,
  NewService,
  Restaurant,
  RestaurantService,
  UserLocation,
  Service,
} from '../../interfaces';

//Import react components
import Alert from './alerts/Alert';
import RestaurantList from './RestaurantList';
import UserList from '../UserComponents/UserList';
import CompanyInputCard from './InputComponents/CompanyInputCard';
import CollapseAndOpenButton from '../Common/Buttons/CollapseAndOpenButton';
import UpdateButton from '../Common/Buttons/UpdateButton';
import CompanyContext from '../../context/CompanyContext';
import { COMPANY_SERVICE_INPUT_MODE } from '../../constant/General';
import InputCardsContext from '../../context/InputCardsContext';
import UserContext from '../../context/UserContext';

const CompanyCard = (props: { companyService: CompanyService }) => {
  const companyContext = useContext(CompanyContext);
  const inputCardsContext = useContext(InputCardsContext);
  const userContext = useContext(UserContext);

  const [isUserListExpanded, setIsUserListExpanded] = useState(false);

  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');

  const [componentId, setComponentId] = useState('');

  const [editedCompany, setEditedCompany] = useState<Company | null>(null);

  /**
   * Function can add the edited company to map, with the companyId as the key, or remove the key-value if there is no change
   * to the company
   * Note: 'companyId' is not used in this function because there can only be editing of 1 company in this component. However this function is structured
   * to take in the same set of parameters as the props of CompanyInputCard component so as to reduce the number of props for the CompanyInputCard component.
   * @param companyId - companyId of the edited company
   * @param editedCompany - Company object containing the edited attribute of an existing company, or undefined if the company is to be removed
   */
  const updateEditedCompany = (companyId: number, editedCompany?: Company) => {
    if (editedCompany) {
      setEditedCompany(editedCompany);
    } else {
      setEditedCompany(null);
    }
  };

  /**
   * Function is called when user clicks on company bar or the maximum/minimise button to open or close the company card
   */
  const collapseOrExpandCompanyCard = () => {
    const newMapIsOpenedsByCompanyId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId
    );
    const isOpeneds = newMapIsOpenedsByCompanyId.get(props.companyService.companyId)!;

    const newIsOpened = !isOpeneds.isOpened;
    let newIsRestaurantListOpened = isOpeneds.isRestaurantListOpened;
    const newMapIsOpenedAndParentIdByRestaurantId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );

    if (inputCardsContext.input.companyId === props.companyService.companyId) {
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
    }
    if (!newIsOpened) {
      newIsRestaurantListOpened = false;
      newMapIsOpenedAndParentIdByRestaurantId.forEach((isOpenedAndParentId, restaurantId) => {
        if (isOpenedAndParentId.parentId === props.companyService.companyId) {
          newMapIsOpenedAndParentIdByRestaurantId.set(restaurantId, {
            isOpened: false,
            parentId: isOpenedAndParentId.parentId,
          });
        }
      });
      setIsUserListExpanded(false);
      inputCardsContext.deleteAllError();
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    } else {
      if (inputCardsContext.userCardInputId === props.companyService.companyId) {
        inputCardsContext.updateUserCardInputModeAndId(0, 0);
      }
    }
    newMapIsOpenedsByCompanyId.set(props.companyService.companyId, {
      isOpened: newIsOpened,
      isRestaurantListOpened: newIsRestaurantListOpened,
    });
    companyContext.updateMapsIsOpenedById({
      mapIsOpenedsByCompanyId: newMapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: newMapIsOpenedAndParentIdByRestaurantId,
    });
  };

  /**
   * Function is called when user clicks on Update Company or Cancel button, and enables or disables update mode by setting the correct input mode
   * in the inputCardsContext
   */
  const enableUpdateCompanyOnClick = () => {
    const isUpdateCompany = inputCardsContext.checkIfActiveInputMode(
      COMPANY_SERVICE_INPUT_MODE.updateCompany,
      componentId
    );
    if (isUpdateCompany === true) {
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
      setComponentId('');
    } else {
      const newComponentId = new Date();
      setComponentId(newComponentId.toString());
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.updateCompany,
        newComponentId.toString(),
        props.companyService.companyId
      );
    }
  };

  /**
   * Function is run by save changes button, and calls saveChangesToExistingCompanyService function. Function saves edited company (if any).
   */
  const saveChangesForUpdateCompanyOnClick = () => {
    saveChangesToExistingCompanyService(
      { companyId: props.companyService.companyId },
      {},
      {
        companyToBeUpdated: editedCompany !== null ? editedCompany : undefined,
      }
    );
    // Reset changes
    setEditedCompany(null);
  };

  /**
   * Function is called on button press to save edit or add changes to existing companyService. It checks if there is any errors in all the inputs
   * that is stored in the InputCardsContxt, before sending the data to the Backend for updates. There are 2 types of data to be sent to the Backend,
   * namely the elements to be created (arrRestaurantServiceToBeCreated, arrLocationServiceToBeCreated, arrServiceToBeCreated) and the elements to be
   * updated (companyToBeUpdated, restaurantToBeUpdated, locationToBeUpdated and arrServiceToBeUpdated). These data structures are the same as the Database
   * tables, and hence the backend code can handle it easier. If there is no changes for any of the object/array, it will be sent to the Backend as undefined.
   * Upon successfully update of the changes and/or creation of the new elements, the changes will be updated to the Context values so the Frontend can display
   * the new changes.
   */
  const saveChangesToExistingCompanyService = async (
    idParameters: {
      companyId: number;
      restaurantId?: number;
      locationId?: number;
    },
    elementsToBeCreated: {
      arrRestaurantServiceToBeCreated?: Array<NewRestaurantService>;
      arrLocationServiceToBeCreated?: Array<NewLocationService>;
      arrServiceToBeCreated?: Array<NewService>;
    },
    elementsToBeUpdated: {
      companyToBeUpdated?: Company;
      restaurantToBeUpdated?: Restaurant;
      locationToBeUpdated?: Location;
      arrServiceToBeUpdated?: Array<Service>;
    }
  ) => {
    try {
      const { companyId, restaurantId, locationId } = idParameters;
      const {
        arrRestaurantServiceToBeCreated,
        arrLocationServiceToBeCreated,
        arrServiceToBeCreated,
      } = elementsToBeCreated;
      const {
        companyToBeUpdated,
        restaurantToBeUpdated,
        locationToBeUpdated,
        arrServiceToBeUpdated,
      } = elementsToBeUpdated;

      const isErrorExist = inputCardsContext.getErrorExist();

      if (isErrorExist === true) {
        setArrAlertMessage(['Please check for any errors and for any empty fields!']);
        setAlertHeader('Save error!');
        setIsAlert(true);
      } else if (
        companyToBeUpdated === undefined &&
        restaurantToBeUpdated === undefined &&
        locationToBeUpdated === undefined &&
        arrServiceToBeUpdated === undefined &&
        arrRestaurantServiceToBeCreated === undefined &&
        arrLocationServiceToBeCreated === undefined &&
        arrServiceToBeCreated === undefined
      ) {
        setArrAlertMessage(['No changes have been made!']);
        setAlertHeader('Save Error!');
        setIsAlert(true);
        inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
      } else {
        if (
          companyToBeUpdated !== undefined ||
          restaurantToBeUpdated !== undefined ||
          locationToBeUpdated !== undefined ||
          arrServiceToBeUpdated !== undefined
        ) {
          // Save edited data
          await axios.post('/company-management/update-company-service', {
            companyId,
            companyToBeUpdated,
            arrRestaurantToBeUpdated: restaurantToBeUpdated && [restaurantToBeUpdated],
            arrLocationToBeUpdated: locationToBeUpdated && [locationToBeUpdated],
            arrServiceToBeUpdated,
          });

          companyContext.updateCompanyServiceDetails(
            idParameters,
            companyToBeUpdated,
            restaurantToBeUpdated,
            locationToBeUpdated,
            arrServiceToBeUpdated
          );
        }
        if (
          arrRestaurantServiceToBeCreated !== undefined ||
          arrLocationServiceToBeCreated !== undefined ||
          arrServiceToBeCreated !== undefined
        ) {
          // Save new data
          const response = await axios.post(
            '/company-management/create-to-existing-company-service',
            {
              arrRestaurantServiceToBeCreated,
              arrLocationServiceToBeCreated,
              arrServiceToBeCreated,
            }
          );
          const { arrRestaurantService, arrLocationService, arrService } = response.data;
          if (arrRestaurantService && companyId) {
            companyContext.addArrRestaurantService(companyId, arrRestaurantService);
          } else if (arrLocationService && companyId && restaurantId) {
            companyContext.addArrLocationService(companyId, restaurantId, arrLocationService);
            // If all user restaurant location details has already been fetched, add the newly created location(s) to all the user(s) who has access
            // to the property that the newly created location(s) belongs to
            if (userContext.mapArrUserRestaurantLocationByCompanyId.size !== 0) {
              const arrLocation = arrLocationService.map((locationService: LocationService) => {
                return {
                  locationId: locationService.locationId,
                  name: locationService.name,
                  restaurantId: locationService.restaurantId,
                };
              });
              userContext.addLocationAccessToUser(companyId, restaurantId, arrLocation);
            }
          } else if (arrService && companyId && restaurantId && locationId) {
            companyContext.addArrService(companyId, restaurantId, locationId, arrService);
          }
        }
        setArrAlertMessage([]);
        setAlertHeader('Save success!');
        setIsAlert(true);
        setIsSaveSuccess(true);
        inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data from context done in Add/Edit mode
      }
    } catch (error: any) {
      let { error: errorTitle, arrServiceId } = error.response.data;
      let errorMessage = '';

      if (errorTitle === 'GroupCoverError') {
        errorMessage =
          'Service group(s) do not share the same cover. Please ensure services to be grouped have the exact same covers!';
      } else if (errorTitle === 'ProductionCoverError') {
        errorMessage =
          'Production service cover found. Please ensure production services do not have any covers!';
      } else if (arrServiceId) {
        errorMessage =
          'Menu for the following service(s) cannot be updated because there is tagged waste in the service(s):';

        // This is to find the company, restaurant, location and service name of the service where the menu update is rejected
        // so that the user can better identify which service updates are throwing this error
        const mapRestaurantServiceNameByServiceId = new Map();
        props.companyService.arrRestaurantService.forEach(
          (restaurantService: RestaurantService) => {
            restaurantService.arrLocationService.forEach((locationService: LocationService) => {
              locationService.arrService.forEach((service: Service) =>
                mapRestaurantServiceNameByServiceId.set(service.serviceId, {
                  companyName: props.companyService.name,
                  restaurantName: restaurantService.name,
                  locationName: locationService.name,
                  serviceName: service.name,
                })
              );
            });
          }
        );
        arrServiceId.forEach((serviceId: number) => {
          const restaurantServiceName = mapRestaurantServiceNameByServiceId.get(serviceId);
          errorMessage += `\n${restaurantServiceName.companyName}/${restaurantServiceName.restaurantName}/${restaurantServiceName.locationName}/${restaurantServiceName.serviceName} (Service Id: ${serviceId})`;
        });
      }
      setArrAlertMessage([
        'Could not create/update data!',
        errorMessage !== '' ? errorMessage : errorTitle,
      ]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  /**
   * Function is called when users clicks on button to open/close all restaurant cards of a company. If the user closes the all the restaurant cards while in any of the
   * the update restaurant/location/service or add restaurant/location/service mode of that company, these modes should be inactivated and all input modes' buttons should
   * be enabled.
   * @param isExpand - Indicates if all the restaurant cards should be expanded or collapsed
   */
  const enableCollapseOrExpandRestaurantCardsOnClick = (isExpand: boolean) => {
    if (
      inputCardsContext.input.inputModeIndex !== COMPANY_SERVICE_INPUT_MODE.addCompanyService &&
      inputCardsContext.input.inputModeIndex !== COMPANY_SERVICE_INPUT_MODE.updateCompany &&
      inputCardsContext.input.companyId === props.companyService.companyId &&
      !isExpand
    ) {
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    }

    const newMapIsOpenedAndParentIdByRestaurantId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );
    newMapIsOpenedAndParentIdByRestaurantId.forEach((isOpenedAndParentId, restaurantId) => {
      if (isOpenedAndParentId.parentId === props.companyService.companyId) {
        newMapIsOpenedAndParentIdByRestaurantId.set(restaurantId, {
          isOpened: isExpand,
          parentId: isOpenedAndParentId.parentId,
        });
      }
    });
    companyContext.updateMapsIsOpenedById({
      mapIsOpenedsByCompanyId: companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: newMapIsOpenedAndParentIdByRestaurantId,
    });
  };

  /**
   * Function is called when users clicks on button to open/close Restaurant bar. If the user closes the Restaurant bar while in the update restaurant/location/service
   * or add restaurant/location/service mode of that company, these modes should be inactivated and all input modes' buttons should be enabled.
   */
  const collapseOrExpandRestaurantListOnClick = () => {
    if (
      inputCardsContext.input.inputModeIndex !== COMPANY_SERVICE_INPUT_MODE.addCompanyService &&
      inputCardsContext.input.inputModeIndex !== COMPANY_SERVICE_INPUT_MODE.updateCompany &&
      inputCardsContext.input.companyId === props.companyService.companyId
    ) {
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    }

    const newMapIsOpenedsByCompanyId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId
    );
    const isOpeneds = companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId.get(
      props.companyService.companyId
    )!;
    const newIsRestaurantListOpened = !isOpeneds.isRestaurantListOpened;

    newMapIsOpenedsByCompanyId.set(props.companyService.companyId, {
      ...isOpeneds,
      isRestaurantListOpened: newIsRestaurantListOpened,
    });

    companyContext.updateMapsIsOpenedById({
      mapIsOpenedsByCompanyId: newMapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId:
        companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId,
    });
  };

  /**
   * Function is called when user clicks on dropdown beside user, fetches users from this company and saves data for UserList to render
   */
  const expandUserOnClick = () => {
    if (
      isUserListExpanded &&
      inputCardsContext.userCardInputId === props.companyService.companyId
    ) {
      inputCardsContext.updateUserCardInputModeAndId(0, 0);
    }
    setIsUserListExpanded(!isUserListExpanded);
  };

  /**
   * Function is called when user expands an user card, to retrieve all the users that belongs to the company, as well as
   * an array of all existing email that will be used to validate if the new email is unique when creating new users for that
   * company. Data is retrieved from the context if it has been fetched before, else the data will be fetched from the Backend
   * and saved in the Frontend.
   */
  const fetchUserDetails = async () => {
    try {
      if (userContext.mapArrUserRestaurantLocationByCompanyId.size === 0) {
        const response = await axios.post('/user-management/fetch-user-details', {});
        const arrFetchedUserLocation: Array<UserLocation> = response.data.arrUserLocation;
        userContext.initialiseAllUserDetails(
          arrFetchedUserLocation,
          companyContext.arrCompanyService
        );
      }
    } catch (error) {
      setArrAlertMessage([`Could not fetch user data!`, `${error}`]);
      setAlertHeader(`Fetch users error!`);
      setIsAlert(true);
    }
  };

  useEffect(() => {
    if (isUserListExpanded) {
      fetchUserDetails();
    }
  }, [isUserListExpanded]);

  const alertHandler = () => {
    if (isSaveSuccess) {
      setIsSaveSuccess(false);
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
    }
    setIsAlert(!isAlert);
  };

  const { isOpened: isCompanyCardOpened, isRestaurantListOpened } =
    companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId.get(props.companyService.companyId)!;
  return (
    <li className={`GroupCard ${isCompanyCardOpened ? `lg:col-span-2` : `col-span-1`}`}>
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={alertHandler}
          isExpanded={isAlert}
        />
      )}
      <div className="rounded-lg p-4 flex-col mt-2 border border-gray-300">
        {inputCardsContext.checkIfActiveInputMode(
          COMPANY_SERVICE_INPUT_MODE.updateCompany,
          componentId
        ) ? (
          <div className="flex justify-between">
            <CompanyInputCard
              arrNewCompany={[]}
              companyService={props.companyService}
              companyId={props.companyService.companyId}
              updateCompany={updateEditedCompany}
            />
            <UpdateButton
              isEnabled={inputCardsContext.getInputMode(
                COMPANY_SERVICE_INPUT_MODE.updateCompany,
                componentId
              )}
              enableUpdateOnClick={enableUpdateCompanyOnClick}
              saveChangesOnClick={saveChangesForUpdateCompanyOnClick}
              isUpdate={inputCardsContext.checkIfActiveInputMode(
                COMPANY_SERVICE_INPUT_MODE.updateCompany,
                componentId
              )}
              buttonName={'Update Group'}
              buttonClassName={'UpdateGroupButton'}
              positionType=""
            />
          </div>
        ) : (
          <div
            onClick={collapseOrExpandCompanyCard}
            className="flex gap-x-1 flex-nowrap justify-between items-center"
          >
            <div className="flex">
              <h1 className="text-xl font-semibold truncate flex flex-col">
                <div className="text-[0.6rem] p-0 text-gray-500 leading-none">
                  Group Id: {props.companyService.companyId}
                </div>
                {props.companyService.name}
              </h1>
            </div>
            <div className="flex gap-x-2 h-fit">
              {isCompanyCardOpened && (
                <UpdateButton
                  isEnabled={inputCardsContext.getInputMode(
                    COMPANY_SERVICE_INPUT_MODE.updateCompany,
                    componentId
                  )}
                  enableUpdateOnClick={enableUpdateCompanyOnClick}
                  saveChangesOnClick={saveChangesForUpdateCompanyOnClick}
                  isUpdate={inputCardsContext.checkIfActiveInputMode(
                    COMPANY_SERVICE_INPUT_MODE.updateCompany,
                    componentId
                  )}
                  buttonName={'Update Group'}
                  buttonClassName={'UpdateGroupButton'}
                  positionType=""
                />
              )}
              {props.companyService.isAirline && (
                <MdAirplanemodeActive className="h-6 w-6 text-off-black self-center" />
              )}
              {!inputCardsContext.checkIfActiveInputMode(
                COMPANY_SERVICE_INPUT_MODE.updateCompany,
                componentId
              ) && (
                <IconButton
                  variant="text"
                  size="sm"
                  className="CompanyButton p-0"
                  onClick={collapseOrExpandCompanyCard}
                >
                  {isCompanyCardOpened ? (
                    <MdMinimize className="CompanyCloseButton text-off-black w-4 h-4 rotate-180" />
                  ) : (
                    <MdOpenInFull className="text-off-black w-4 h-4" />
                  )}
                </IconButton>
              )}
            </div>
          </div>
        )}
        {isCompanyCardOpened && (
          <div className="GroupCardExpanded mt-3 flex flex-col gap-x-1 gap-y-3 flex-nowrap justify-between content-center ">
            <div
              className={`flex flex-col justify-between flex-grow bg-gray-100 rounded-lg ${
                isRestaurantListOpened ? `p-4` : `p-2`
              }`}
            >
              <div
                onClick={collapseOrExpandRestaurantListOnClick}
                className={`flex flex-grow ${
                  isRestaurantListOpened ? 'justify-end' : 'justify-between'
                }`}
              >
                {isRestaurantListOpened ? (
                  <div className="flex px-4">
                    <CollapseAndOpenButton
                      type="Property"
                      enableCollapseOrExpandOnClick={enableCollapseOrExpandRestaurantCardsOnClick}
                    />
                  </div>
                ) : (
                  <p className="PropertyListUnexpanded text-lg font-medium">Properties</p>
                )}
                <IconButton variant="text" size="sm" className="PropertyButton p-0 flex-grow">
                  <MdKeyboardArrowDown
                    style={{
                      transform: isRestaurantListOpened ? 'rotate(180deg)' : 'rotate(0)',
                      transition: 'all 0.1s linear',
                    }}
                    className="h-7 w-7 text-off-black"
                  />
                </IconButton>
              </div>
              <ul className="flex flex-col w-full">
                {isRestaurantListOpened && (
                  <RestaurantList
                    companyService={props.companyService}
                    saveChangesToExistingCompanyService={saveChangesToExistingCompanyService}
                  />
                )}
              </ul>
            </div>
            <div
              className={`flex flex-col justify-between flex-grow bg-gray-100 rounded-lg ${
                isUserListExpanded ? 'p-4' : `p-2`
              }`}
            >
              <div
                onClick={expandUserOnClick}
                className={`UserListUnexpanded flex flex-grow ${
                  isUserListExpanded ? 'justify-end' : 'justify-between'
                }`}
              >
                {!isUserListExpanded && <h1 className="text-lg font-medium">Users</h1>}
                <IconButton variant="text" size="sm" className="p-0">
                  <MdKeyboardArrowDown
                    style={{
                      transform: isUserListExpanded ? 'rotate(180deg)' : 'rotate(0)',
                      transition: 'all 0.1s linear',
                    }}
                    className="h-7 w-7 text-off-black"
                  />
                </IconButton>
              </div>
              {isUserListExpanded && <UserList companyService={props.companyService} />}
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default CompanyCard;
